import React from "react";
import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";

class ModalInsSpeseMateriali extends React.Component<{
    open: boolean,
    toggle: () => void,
    value: string,
    onChange: (e) => void,
    value1: string,
    onChange1: (e) => void,
    value2: string,
    onChange2: (e) => void,
    onClick: () => void,
    onClick1: () => void
}> {
    render() {
        return <Modal isOpen={this.props.open} toggle={this.props.toggle}>
            <ModalHeader toggle={this.props.toggle}>Aggiungi Costo Aggiuntivo</ModalHeader>
            <ModalBody>

                <FormGroup>
                    <Label for="descrizioneCosto">Descrizione</Label>
                    <Input type="text" id="descrizioneCosto" value={this.props.value}
                           onChange={this.props.onChange}/>
                </FormGroup>

                <Row form>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="quantitaCosto">Quantità</Label>
                            <Input type="number" id="quantitaCosto" value={this.props.value1}
                                   onChange={this.props.onChange1}/>
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="importoCosto">Importo (€)</Label>
                            <Input type="number" step="0.01" id="importoCosto" value={this.props.value2}
                                   onChange={this.props.onChange2}/>
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="totale">Totale (€)</Label>
                            <Input type="number" step="0.01" id="totale" value={this.props.value2 * this.props.value1}
                                   readOnly/>
                        </FormGroup>
                    </Col>
                </Row>

            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.props.onClick}>Annulla</Button>
                <Button color="primary" onClick={this.props.onClick1}>Salva</Button>
            </ModalFooter>
        </Modal>;
    }
}

export default ModalInsSpeseMateriali;
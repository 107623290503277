import React from "react";
import {Button, Table} from "reactstrap";

class BoxListIntervSpeseMaterial extends React.Component<{
    onClick: () => void,
    interventionsDone: [],
    callbackfn: (intervention, index) => any,
    callbackfn1: (prev, cur) => any,
    callbackfn2: (prev, cur) => any,
    onClick1: () => void,
    additionalCosts: [],
    callbackfn3: (cost, index) => any,
    callbackfn4: (prev, cur) => any
}> {
    render() {
        return <>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginTop: "10px",
                border: "1px solid #ccc",
                backgroundColor: "#f2f2f2",
                padding: "10px",
                borderRadius: "5px",
                height: "500px",

            }}>
                <div>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        <h5>Interventi</h5>
                        <Button color="primary" className="m-1"
                                onClick={this.props.onClick}>+</Button>
                    </div>
                    <hr/>
                    <div style={{
                        maxHeight: "350px",  // calculate this height
                        overflowY: "auto",
                    }}>
                        <Table>
                            <thead>
                            <tr>
                                <th>Descrizione</th>
                                <th>Ora inizio</th>
                                <th>Ora fine</th>
                                <th>Ore effettive</th>
                                <th>Costo Orario</th>
                                <th>Totale €</th>
                                <th>Azioni</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.interventionsDone.map(this.props.callbackfn)}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px"
                }}>
                    <h5>Ore effettive totali: {
                        this.props.interventionsDone.reduce(this.props.callbackfn1, 0)
                    } ore</h5>
                    <h5>Costo totale: {
                        this.props.interventionsDone.reduce(this.props.callbackfn2, 0)
                    } €</h5>
                </div>
            </div>

            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginTop: "10px",
                border: "1px solid #ccc",
                backgroundColor: "#f2f2f2",
                padding: "10px",
                borderRadius: "5px",
                height: "500px",
            }}>
                <div>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        <h5>Spese/Materiali</h5>
                        <Button color="primary" className="m-1"
                                onClick={this.props.onClick1}>+</Button>
                    </div>
                    <hr/>
                    <div style={{
                        maxHeight: "350px",  // calculate this height
                        overflowY: "auto",
                    }}>
                        <Table>
                            <thead>
                            <tr>
                                <th>Descrizione</th>
                                <th>Q.ta/Ore</th>
                                <th>Valore (€)</th>
                                <th>Totale (€)</th>
                                <th>Azioni</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.additionalCosts.map(this.props.callbackfn3)}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "right",
                    marginTop: "10px"
                }}>
                    <h5>Totale: {
                        this.props.additionalCosts.reduce(this.props.callbackfn4, 0)
                    } €</h5>
                </div>

            </div>
        </>;
    }
}

export default BoxListIntervSpeseMaterial;
const services = [
    {
        id: 1,
        key: "intervento-on-site-con-contratto",
        description: "Intervento ON-SITE con contratto"
    },
    {
        id: 2,
        key: "intervento-on-site-fuori-contratto",
        description: "Intervento ON-SITE fuori contratto"
    },
    {
        id: 3,
        key: "intervento-on-site-attesa-contratto",
        description: "Intervento ON-SITE in attesa di contratto"
    },
    {
        id: 4,
        key: "intervento-on-site-extra-contratto",
        description: "Intervento ON-SITE extra contratto"
    },
    {
        id: 5,
        key: "intervento-teleassistenza-con-contratto",
        description: "Intervento in TELEASSISTENZA con contratto"
    },
    {
        id: 6,
        key: "intervento-teleassistenza-fuori-contratto",
        description: "Intervento in TELEASSISTENZA fuori contratto"
    },
    {
        id: 7,
        key: "intervento-teleassistenza-attesa-contratto",
        description: "Intervento in TELEASSISTENZA in attesa di contratto"
    },
    {
        id: 8,
        key: "intervento-teleassistenza-installazione",
        description: "Intervento in TELEASSISTENZA nuova installazione"
    },
    {
        id: 9,
        key: "intervento-teleassistenza-extra-contratto",
        description: "Intervento in TELEASSISTENZA extra contratto"
    },
    {
        id: 10,
        key: "altro-vedi-note-rapportino",
        description: "Altro (vedi note rapportino)"
    },
];

const intervOptions = [
    {
        id: 0,
        key: "Scegli un'opzione",
        rangeDate: true
    },
    {
        id: 1,
        key: "Assistenza Tecnica",
        rangeDate: true
    },
    {
        id: 2,
        key: "Ore Consulenza",
        rangeDate: true
    },
    {
        id: 3,
        key: "Ore Formazione",
        rangeDate: true
    },
    {
        id: 4,
        key: "Assistenza prova",
        rangeDate: true
    }
];

export {services, intervOptions};
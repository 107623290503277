import React, {useState, useEffect} from 'react';
import DataTable from 'react-data-table-component';

import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import axios from "axios";
import {Navigate} from "react-router-dom";
import {count} from "react-table/src/aggregations";

const ListaClienti = () => {

    const [data, setData] = useState([]);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [navigateTo, setNavigateTo] = useState(null);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [filterNome, setFilterNome] = useState("");


    const apiUrl = process.env.REACT_APP_API_URL_MONGO;
    const token = process.env.REACT_APP_TOKEN_MONGO;

    useEffect(() => {


        try {
            axios.get(apiUrl + 'api/list-clienti', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {



                    const mappedData = response.data.map(item => ({
                        id: item.id,
                        nome: item.nome,
                        email: item.email,
                        telefono: item.telefono,
                        prezzo_orario: '€ ' + item.prezzo_orario,
                        n_interventi: count(item.interventi),
                    }));
                    
                    setData(mappedData);

                });

        } catch (error) {
            console.error("Si è verificato un errore durante il recupero dei clienti:", error);
        }


    }, [apiUrl, token]);

    function handleEdit(row) {
        const id = row.id;
        setNavigateTo(`/clienti-modifica/${id}`);
    }

    function handleDelete(rowData) {

        setItemToDelete(rowData.id);

        setIsDeleteModalOpen(true);

    }

    const confirmDelete = () => {
        if (itemToDelete) {

            const formData = {
                id: itemToDelete
            };

            axios.post(apiUrl + 'api/delete-clienti', formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {

                    const {message, status} = response;

                    if (status === 'success') {

                        setIsDeleteModalOpen(false);

                        const updatedData = data.filter(item => item.id !== itemToDelete);
                        setData(updatedData);

                    } else {
                        console.error('Error deleting the record', message); // Usa response.data.message per ottenere il messaggio di errore
                    }
                })
                .catch(error => {
                    console.error('Error deleting the record', error);
                });
        }
    }

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Nome</span>,
            selector: row => row.nome,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Email</span>,
            selector: row => row.email,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Telefono</span>,
            selector: row => row.telefono,
            width: '250px',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Prezzo Orario</span>,
            selector: row => row.prezzo_orario,
            width: '150px',
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>N. Interventi</span>,
            selector: row => row.n_interventi,
            width: '150px',
            sortable: true,

        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            sortable: false,
            cell: (row) => (
                <div>
                    {navigateTo && <Navigate to={navigateTo}/>}
                    <Button onClick={() => handleEdit(row)} color="secondary" size="sm" className="me-2">
                        Edit
                    </Button>
                    <Button onClick={() => handleDelete(row)} color="danger" size="sm" className="me-2">
                        Delete
                    </Button>
                </div>
            ),
        },
    ];

    const resetFilters = () => {
        setFilterNome("");
    };

    const filteredData = data.filter(item => item.nome.toLowerCase().includes(filterNome.toLowerCase()));


    return (
        <>
            <div className='styleFilterTableClienti'>
                <div className="backgroundContainertableClienti">
                    <div>
                        <span>Filtri:</span>
                    </div>

                    <div>
                        <input
                            type="text"
                            placeholder="Filtra per nome..."
                            value={filterNome}
                            onChange={(e) => setFilterNome(e.target.value)}
                            style={{
                                border: filterNome ? '1px solid red' : '1px solid #ccc'
                            }}
                        />
                    </div>
                    <button onClick={resetFilters}>
                        Pulisci Filtro
                    </button>
                </div>
            </div>

            <DataTable
                className="header-tabellaListaClienti"
                columns={columns}
                data={filteredData}
                pagination
            />

            <Modal isOpen={isDeleteModalOpen} toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)} centered>
                <ModalHeader toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}>
                    <div className='attention-header'>Attenzione!</div>
                </ModalHeader>
                <ModalBody>
                    Stai per cancellare questo cliente.<br/> Ricorda che tutti i dati e gli interventi associati a
                    questo cliente verranno cancellati definitivamente.<br/><br/>
                    Sei sicuro di voler procedere?

                    <div className="mt-3">
                        <Button color="danger" onClick={confirmDelete} style={{marginRight: '10px'}}>Si,
                            Cancella</Button>
                        <Button color="secondary" onClick={() => setIsDeleteModalOpen(false)}>Esci</Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );


}

export {ListaClienti};
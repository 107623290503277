import React, {useEffect, useState} from "react";
import logolight from "../../assets/images/logo-GI.png";
import logodark from "../../assets/images/logo-GI.png";

import {Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label} from "reactstrap";

//redux
import {useSelector} from "react-redux";

import {Link} from "react-router-dom";
import { useNavigate } from 'react-router-dom';

// Formik validation
import * as Yup from "yup";
import {useFormik} from "formik";

import axios from "axios";


const Login = props => {
    document.title = "Login | Gestione Interventi";

    const apiUrl = process.env.REACT_APP_API_URL_MONGO;
    const token = process.env.REACT_APP_TOKEN_MONGO;

    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email:  '',
            password: '',
        },


        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            password: Yup.string().required("Please Enter Your Password") .min(6, 'Password must be at least 6 characters'),
        }),

        onSubmit: (values) => {

            axios.post(apiUrl + 'api/login',values,{
                headers: {
                    'Authorization': 'Bearer '+ token
                }
            //     axios.post(apiUrl +'api/login',values,{
            //     headers: {
            //         'Authorization': `Bearer ${token}`
            //     }
            }).then(response => {

                if(response.success){
                    localStorage.setItem("authUser", JSON.stringify(response.data));
                    navigate('/dashboard');

                }else{
                    setErrorMessage(response.message);
                }

            })
        }
    });

    const {error} = useSelector(state => ({
        error: state.login.error,
    }));

    useEffect(() => {
        document.body.className = "bg-pattern";
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });

    return (
        <React.Fragment>

            <div className="bg-overlay"></div>
            <div className="account-pages my-5 pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={6} md={8} xl={4}>
                            <Card>
                                <CardBody className="p-4">
                                    <div>
                                        <div className="text-center">
                                            <Link to="/">
                                                <img
                                                    src={logodark}
                                                    alt=""
                                                    height="50"
                                                    className="auth-logo logo-dark mx-auto"
                                                />
                                                <img
                                                    src={logolight}
                                                    alt=""
                                                    height="50"
                                                    className="auth-logo logo-light mx-auto"
                                                />
                                            </Link>
                                        </div>
                                        <h4 className="font-size-18 text-muted mt-2 text-center">
                                            Welcome Back !
                                        </h4>
                                        <p className="mb-5 text-center">
                                            Sign in to continue to Gestione Interventi.
                                        </p>
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            {error ? <Alert color="danger">
                                                <div>{error}</div>
                                            </Alert> : null}
                                            <Row>
                                                <Col md={12}>
                                                    <div className="mb-4">
                                                        <Label className="form-label">Email</Label>
                                                        <Input
                                                            name="email"
                                                            className="form-control"
                                                            placeholder="Enter email"
                                                            type="email"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email || ""}
                                                            invalid={
                                                                !!(validation.touched.email && validation.errors.email)
                                                            }
                                                        />
                                                        {validation.touched.email && validation.errors.email ? (
                                                            <FormFeedback type="invalid">
                                                                <div>{validation.errors.email}</div>
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="mb-4">
                                                        <Label className="form-label">Password</Label>
                                                        <Input
                                                            name="password"
                                                            value={validation.values.password || ""}
                                                            type="password"
                                                            placeholder="Enter Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                !!(validation.touched.password && validation.errors.password)
                                                            }
                                                        />
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">
                                                                <div> {validation.errors.password} </div>
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <Row>
                                                        <Col>
                                                            <div className="form-check">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="customControlInline"
                                                                />
                                                                <label
                                                                    className="form-label form-check-label"
                                                                    htmlFor="customControlInline"
                                                                >
                                                                    Remember me
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        <Col className="col-7">
                                                            <div className="text-md-end mt-3 mt-md-0">
                                                                <Link
                                                                    to="/auth-recoverpw"
                                                                    className="text-muted"
                                                                >
                                                                    <i className="mdi mdi-lock"></i> Forgot your
                                                                    password?
                                                                </Link>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {errorMessage && <div className="text-danger mt-4 medium text-center">{errorMessage}</div>}
                                                    <div className="d-grid mt-4">
                                                        <button
                                                            className="btn btn-primary waves-effect waves-light"
                                                            type="submit"
                                                        >
                                                            Log In
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p className="text-white-50">
                                    Don't have an account ?{" "}
                                    <Link to="/register" className="fw-medium text-primary">
                                        {" "}
                                        Register{" "}
                                    </Link>{" "}
                                </p>
                                <p className="text-white-50">
                                    © {new Date().getFullYear()} Web Lab Torino
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Login;

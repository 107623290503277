const SidebarData = [
    {
        label: "Menu",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        issubMenubadge: true,
    },
    {
        label: "Calendario",
        icon: "mdi mdi-calendar",
        url: "/calendar",
        issubMenubadge: true,
    },
    {
        label: "Interventi",
        icon: "mdi mdi-file-cog",
        subItem: [
            {sublabel: "Lista Interventi", link: "/interventi"},
            {sublabel: "Nuovo Intervento", link: "/interventi-crea"},

        ],
    },
    {
        label: "Clienti",
        icon: "mdi mdi-account-multiple",
        subItem: [
            {sublabel: "Lista Clienti", link: "/clienti"},
            {sublabel: "Nuovo Cliente", link: "/clienti-inserisci"},
        ],
    },
    // {
    //     label: "Fatturazione",
    //     icon: "mdi mdi-account-multiple",
    //     subItem: [
    //         {sublabel: "Dati Fatturazione Cliente", link: "/billing-cliente"},
    //         {sublabel: "Fattura", link: "/fattura"},
    //
    //     ]
    // }

]
export default SidebarData;
import React from "react";
import { Container} from "reactstrap";
//Import Breadcrumb


import Breadcrumbs from "../../components/Common/Breadcrumb";
import ModificaCliente from "./Component/ModificaCliente";



const ModificaCl = () => {
    document.title = "Dashboard | Gestione Clienti";
    return (
        <React.Fragment>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Modifica Clienti" breadcrumbItem="Clienti" />
                        <ModificaCliente />
                    </Container>
                </div>
            </React.Fragment>
        </React.Fragment>
    );
};

export default ModificaCl;
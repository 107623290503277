import React from "react";

import { Row, Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import InserisciClienti from "./Component/ClientiForm";


const InseClienti = () => {
    document.title = "Dashboard | Crea Intervento";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Inserisci Clienti" breadcrumbItem="Interventi" />
                    <InserisciClienti />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default InseClienti;
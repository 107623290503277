import React from "react";

import { Row, Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import InterventiForm from "./Component/InterventiForm";


const Crea = () => {
    document.title = "Dashboard | Crea Intervento";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Crea Intervento" breadcrumbItem="Interventi" />
                    <InterventiForm />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Crea;
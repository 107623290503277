import React from "react";
import {Row, Container, Col, Card, CardHeader, CardBody} from "reactstrap";
//Import Breadcrumb


import Breadcrumbs from "../../components/Common/Breadcrumb";
import {ListaClienti} from "./Component/ListaClienti";


const ClientiLista = () => {
    document.title = "Dashboard | Gestione Clienti";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Gestione Clienti" breadcrumbItem="Clienti" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">Lista Clienti</h5>
                                </CardHeader>
                                <CardBody>
                                    <ListaClienti />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ClientiLista;
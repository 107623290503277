import React, {useState, useEffect} from 'react';
import DataTable from 'react-data-table-component';

import {
    Button, Card, CardBody, CardTitle, Col,
    Modal,
    ModalBody,
    ModalHeader, Row,
} from 'reactstrap';
import axios from "axios";
import {Link, Navigate} from "react-router-dom";

const ListaInterventi = () => {

    const [data, setData] = useState([]);
    const [currentItemDetails, setCurrentItemDetails] = useState({});

    const [navigateTo, setNavigateTo] = useState(null);
    const [fatturaNavigate, setFatturaNavigate] = useState(null);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    const [filterClienti, setFilterClienti] = useState("");

    const [filterStato, setFilterStato] = useState("");
    const [filterFatturato, setFilterFatturato] = useState("");


    const apiUrl = process.env.REACT_APP_API_URL_MONGO;
    const token = process.env.REACT_APP_TOKEN_MONGO;

    useEffect(() => {
        axios.get(apiUrl + 'api/lista-interventi', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                const formatDate = (dateString) => {
                    const date = new Date(dateString);
                    const day = String(date.getDate()).padStart(2, '0');
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const year = date.getFullYear();
                    return `${day}/${month}/${year}`;
                }

                const mappedData = response.data.map(item => {
                    // Calcola il totale con l'aggiunta del 4% di imposta
                    let totaleConImposta = parseFloat(item.totale_intervento) * 1.04;

                    // Formatta il totale in euro
                    let formattedTotal = totaleConImposta.toLocaleString('it-IT', {style: 'currency', currency: 'EUR'});

                    return {
                        id: item.id,
                        cliente: item.cliente.nome,
                        id_cliente: item.cliente_id.id,
                        email: item.cliente.email,
                        telefono: item.cliente.telefono,
                        descrizione: item.descrizione,
                        ore_lavorate: item.ore_lavorate,
                        stato: item.stato,
                        fatturato: item.fatturato,
                        data_inizio: formatDate(item.data_inizio),
                        data_fine: formatDate(item.data_fine),
                        prezzo_orario: item.prezzo_orario,
                        costi_aggiuntivi: item.costi_aggiuntivi,
                        totale_intervento: formattedTotal
                    };
                });

                setData(mappedData);
            })
            .catch(error => {
                console.error('Error fetching the data', error);
            });
    }, [apiUrl, token]);


    function getUniqueValues(field) {
        return [...new Set(data.map(item => item[field]))];
    }

    const statoOptions = getUniqueValues('stato');
    const fatturatoOptions = [1, 0];  // Assumo che tu abbia solo due opzioni: 1 e 0

    const filteredItems = data.filter(item =>
        (filterClienti === "" || item.cliente === filterClienti) &&
        (filterStato === "" || item.stato === filterStato) &&
        (filterFatturato === "" || item.fatturato.toString() === filterFatturato)
    );

    const FilterComponents = ({tableData}) => {
        const uniqueClienti = [...new Set(tableData.map(item => item.cliente))];

        const clearFilters = () => {
            setFilterClienti("");
            setFilterStato("");
            setFilterFatturato("");
        };

        return (
            <div className='styleFilterTableInterventi'>
                <div className="backgroundContainertableInterventi">
                    <span style={{marginRight: "10px"}}>Filtri:</span>
                    <FilterComponentClienti clienti={uniqueClienti}/>
                    <FilterComponentStato/>
                    <FilterComponentFatturato/>
                    <button
                        onClick={clearFilters}
                    >
                        Pulisci Filtri
                    </button>
                </div>
            </div>
        );
    };

    const FilterComponentClienti = ({clienti}) => {
        return (
            <div className='styleOptionTabellaInterventi'>
                Clienti:
                <select
                    style={{
                        border: filterClienti ? '1px solid red' : '1px solid #ccc'
                    }}
                    value={filterClienti} onChange={e => setFilterClienti(e.target.value)}>
                    <option value="">Tutti</option>
                    {clienti && clienti.map(cliente => <option key={cliente} value={cliente}>{cliente}</option>)}
                </select>
            </div>
        );
    };

    const FilterComponentStato = () => {
        return (
            <div className='styleOptionTabellaInterventi'>
                Stato:
                <select
                    style={{
                        border: filterStato ? '1px solid red' : '1px solid #ccc'
                    }}
                    value={filterStato}
                    onChange={e => setFilterStato(e.target.value)}>
                    <option value="">Tutti</option>
                    {statoOptions.map(stato => <option key={stato} value={stato}>{stato}</option>)}
                </select>
            </div>
        );
    };


    const FilterComponentFatturato = () => {
        return (
            <div className='styleOptionTabellaInterventi'>
                Fatturato:
                <select
                    style={{
                        border: filterFatturato ? '1px solid red' : '1px solid #ccc'
                    }}
                    value={filterFatturato}
                    onChange={e => setFilterFatturato(e.target.value)}>
                    <option value="">Tutti</option>
                    {fatturatoOptions.map(fatturato => <option key={fatturato}
                                                               value={fatturato}>{fatturato === 1 ? "Si" : "No"}</option>)}
                </select>
            </div>
        );
    };


    const handleView = (rowData) => {
        // Imposta i dettagli dell'elemento corrente

        setCurrentItemDetails(rowData);

        // Apri il modal
        setIsModalOpen(true);
    };

    const handleEdit = (rowData) => {
        const id = rowData.id;
        setNavigateTo(`/interventi-modifica/${id}`);
    };

    const handleFattura = (rowData) => {
        setFatturaNavigate({
            pathname: `/fattura/${rowData.id}/cliente/${rowData.id_cliente}`,
            state: {id: rowData.id, id_cliente: rowData.id_cliente}
        });
    };

    const handleDelete = (rowData) => {
        setItemToDelete(rowData.id);        // Open the confirmation modal
        setIsDeleteModalOpen(true);

    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

    };

    const confirmDelete = () => {
        if (itemToDelete) {

            const formData = {
                id: itemToDelete
            };

            axios.post(apiUrl + 'api/delete-intervento', formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {

                    if (response.status == 'success') {

                        setIsDeleteModalOpen(false);

                        const updatedData = data.filter(item => item.id !== itemToDelete);
                        setData(updatedData);

                    } else {
                        console.error('Error deleting the record', response.data.message); // Usa response.data.message per ottenere il messaggio di errore
                    }
                })
                .catch(error => {
                    console.error('Error deleting the record', error);
                });
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Cliente</span>,
            selector: row => row.cliente,
            width: '200px',
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Descrizione Intervento</span>,
            selector: row => row.descrizione,
            width: '300px',
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Ore Lavorate</span>,
            selector: row => row.ore_lavorate,
            width: '100px',
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Totale Intervento + 4%</span>,
            selector: row => row.totale_intervento,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Stato</span>,
            sortable: true,
            width: '100px',
            selector: (row) => {
                switch (row.stato) {
                    case "cancellato":
                        return <span className="badge badge-soft-danger"> {capitalizeFirstLetter(row.stato)} </span>;
                    case "completato":
                        return <span className="badge badge-soft-success"> {capitalizeFirstLetter(row.stato)} </span>;
                    case "in_corso":
                        return <span className="badge badge-soft-warning"> {capitalizeFirstLetter(row.stato)} </span>;
                    case "programmato":
                        return <span className="badge badge-soft-info"> {capitalizeFirstLetter(row.stato)} </span>;
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Fatturato</span>,
            selector: (row) => {
                switch (row.fatturato) {
                    case 1:
                        return <span className="badge badge-soft-success"> SI </span>;
                    case 0:
                        return <span className="badge badge-soft-danger"> NO </span>;

                }
            },
            width: '100px',
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            sortable: false,
            cell: (row) => (
                <div>
                    {navigateTo && <Navigate to={navigateTo}/>}
                    {fatturaNavigate && <Navigate to={fatturaNavigate}/>}
                    <Button onClick={() => handleView(row)} color="primary" size="sm" className="me-2">
                        View
                    </Button>
                    <Button onClick={() => handleEdit(row)} color="secondary" size="sm" className="me-2">
                        Edit
                    </Button>
                    <Button onClick={() => handleDelete(row)} color="danger" size="sm" className="me-2">
                        Delete
                    </Button>
                    <Button onClick={() => handleFattura(row)} color="info" size="sm" disabled={true}>
                        Fattura
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <>
            <DataTable
                className='header-tabellaListaInterventi'
                columns={columns}
                data={filteredItems}
                pagination
                subHeader
                subHeaderComponent={<FilterComponents tableData={data}/>}
            />

            <Modal isOpen={isDeleteModalOpen} toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)} centered>
                <ModalHeader toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}>
                    Cancella Intervento
                </ModalHeader>
                <ModalBody>
                    Sei sicuro di voler cancellare questo intervento?
                    <div className="mt-3">
                        <Button color="danger" onClick={confirmDelete} style={{marginRight: '10px'}}>Si,
                            Cancella</Button>
                        <Button color="secondary" onClick={() => setIsDeleteModalOpen(false)}>Esci</Button>
                    </div>
                </ModalBody>
            </Modal>


            <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)} centered size={'xl'}>
                <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>
                    <p>Dettagli Intervento</p>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        {/* Dati Cliente Card */}
                        <Col md="4">
                            <Card className="custom-border mb-4">
                                <CardBody>
                                    <CardTitle><h5>Dati Cliente</h5></CardTitle>
                                    <div className="mb-2">
                                        <span className="font-weight-bold">Cliente:</span> {currentItemDetails.cliente}
                                    </div>
                                    <div className="mb-2">
                                        <span
                                            className="font-weight-bold">Email Cliente:</span> {currentItemDetails.email}
                                    </div>
                                    <div className="mb-2">
                                        <span
                                            className="font-weight-bold">Telefono Cliente:</span> {currentItemDetails.telefono}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        {/* Dati Intervento Card */}
                        <Col md="4">
                            <Card className="custom-border mb-4">
                                <CardBody>
                                    <CardTitle><h5>Dati Intervento</h5></CardTitle>
                                    <div className="mb-2">
                                        <span
                                            className="font-weight-bold">Descrizione:</span> {currentItemDetails.descrizione}
                                    </div>
                                    <div className="mb-2">
                                        <span
                                            className="font-weight-bold">Data Inizio:</span> {currentItemDetails.data_inizio}
                                    </div>
                                    <div className="mb-2">
                                        <span
                                            className="font-weight-bold">Data Fine:</span> {currentItemDetails.data_fine}
                                    </div>
                                    <div className="mb-2">
                                        <span
                                            className="font-weight-bold">Ore Lavorate:</span> {currentItemDetails.ore_lavorate}
                                    </div>
                                    <div className="mb-2">
                                        <span
                                            className="font-weight-bold">Prezzo orario:</span> € {currentItemDetails.prezzo_orario}
                                    </div>
                                    <div className="mb-2">
                                        <span
                                            className="font-weight-bold">Totale Intervento:</span> {currentItemDetails.totale_intervento}
                                    </div>

                                    <div className="mb-2">
                                        <span className="font-weight-bold">Stato:</span> {currentItemDetails.stato}
                                    </div>
                                    <div className="mb-2">
                                        <span
                                            className="font-weight-bold">Fatturato:</span> {currentItemDetails.fatturato ? 'Sì' : 'No'}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        {/* Costi Aggiuntivi Card */}
                        <Col md="4">
                            <Card className="custom-border mb-4">
                                <CardBody>
                                    <CardTitle><h5>Costi Aggiuntivi</h5></CardTitle>
                                    {currentItemDetails.costi_aggiuntivi && currentItemDetails.costi_aggiuntivi.length > 0 ? (
                                        currentItemDetails.costi_aggiuntivi.map((costo, index) => (
                                            <div key={index} className="mb-2">
                                                <div>
                                                    <span
                                                        className="font-weight-bold">Descrizione:</span> {costo.descrizione}
                                                </div>
                                                <div>
                                                    <span className="font-weight-bold">Importo:</span> € {costo.importo}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>Nessun costo aggiuntivo</p>
                                    )}

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    );
};

export {ListaInterventi};
// import React, {useState, useEffect} from 'react';
// import {Button, Form, FormGroup, Label, Input} from 'reactstrap';
// import axios from "axios";
//
// const BillingDataForm = () => {
//
//     const apiUrl = process.env.REACT_APP_API_URL_MONGO;
//     const token = process.env.REACT_APP_TOKEN_MONGO;
//
//     const [clients, setClients] = useState([]);
//     const [errorMessage, setErrorMessage] = useState(null);
//     const [successMessage, setSuccessMessage] = useState(null);
//     const [contentButton, setContentButton] = useState('Salva');
//     // const [uniqueKey, setUniqueKey] = useState(0);
//     const [data, setData] = useState({
//         clienti_id: "",
//         cliente_nome: "",
//         indirizzo: "",
//         CAP: "",
//         cod_fiscale: "",
//         part_iva: ""
//     });
//
//     useEffect(() => {
//         const fetchClienti = async () => {
//             try {
//                 const response = await axios.get(apiUrl + 'api/list-clienti', {
//                     headers: {
//                         'Authorization': `Bearer ${token}`
//                     }
//                 });
//
//                 if (response.data && Array.isArray(response.data)) {
//                     if (response.data && Array.isArray(response.data)) {
//                         setClients([{id: 0, nome: "Scegli un cliente", prezzoOrario: 0}, ...response.data]);
//                     }
//                 }
//             } catch (error) {
//                 console.error("Si è verificato un errore durante il recupero dei clienti:", error);
//             }
//         };
//
//         fetchClienti();
//
//     }, [apiUrl, token]);
//
//     const handleClientChange = (e) => {
//         const selectedClientId = e.target.value;
//         setData(prevState => ({
//             ...prevState,
//             clienti_id: selectedClientId
//         }));
//
//
//         const fetchBillingCliente = async () => {
//
//             const formData = {
//                 id: selectedClientId
//             };
//
//             try {
//                 const response = await axios.post(apiUrl + `api/billing-cliente`, formData, {
//                     headers: {
//                         'Authorization': `Bearer ${token}`
//                     }
//                 });
//
//                 if (response.data) {
//                     setData(prevState => ({
//                         ...prevState,
//                         cliente_nome: response.data.cliente_nome || "",
//                         indirizzo: response.data.indirizzo || "",
//                         CAP: response.data.CAP || "",
//                         cod_fiscale: response.data.cod_fiscale || "",
//                         part_iva: response.data.part_iva || ""
//                     }));
//                     setContentButton('Aggiorna');
//                 } else {
//                     // Se response.data è null, ripristina tutti i campi tranne cliente_id
//                     setData(prevState => ({
//                         ...prevState,
//                         cliente_nome: "",
//                         indirizzo: "",
//                         CAP: "",
//                         cod_fiscale: "",
//                         part_iva: ""
//                     }));
//
//                     setContentButton('Salva')
//                 }
//
//             } catch (error) {
//                 console.error("Errore nel recupero delle informazioni del cliente:", error.response.data);
//             }
//         };
//
//         fetchBillingCliente();
//     };
//
//     const handleChange = (e) => {
//         const {name, value} = e.target;
//         setData(prevState => ({...prevState, [name]: value}));
//     };
//
//     const handleSubmit = (e) => {
//         e.preventDefault();
//
//         const storeBillingCliente = async () => {
//
//             try {
//                 const response = await axios.post(apiUrl + `api/store-billing-cliente`, data, {
//                     headers: {
//                         'Authorization': `Bearer ${token}`
//                     }
//                 });
//
//                 const {status, message} = response;
//
//                 if (status === 'success') {
//                     setSuccessMessage(message);
//                     setTimeout(() => {
//                         setSuccessMessage('');
//                     }, 3000);
//                 } else {
//                     setErrorMessage(message);
//                     setTimeout(() => {
//                         setErrorMessage('');
//                     }, 3000);
//                 }
//
//
//             } catch (error) {
//                 console.error("Errore nel recupero delle informazioni del cliente:", error.response.data);
//             }
//         };
//
//         storeBillingCliente();
//
//
//     };
//
//     return (
//         <div className="container-fluid mt-4">
//             <div className="row justify-content-center">
//                 <div className="col-md-6">
//                     <div className="card">
//                         <div className="card-body">
//                             <Form onSubmit={handleSubmit}>
//                                 <div className="bg-light">
//                                     <FormGroup className="w-50 mx-auto p-3">
//                                         {/*<Label for="cliente_id">Cliente</Label>*/}
//                                         <Input type="select"
//                                                name="cliente_id"
//                                                id="cliente_id"
//                                                value={data.clienti_id}
//                                                onChange={handleClientChange}>
//                                             {clients.map(cliente => (
//                                                 <option key={cliente.id} value={cliente.id}>
//                                                     {cliente.nome}
//                                                 </option>
//                                             ))}
//                                         </Input>
//                                     </FormGroup>
//                                 </div>
//                                 <hr/>
//                                 <FormGroup>
//                                     <Input type="hidden" name="cliente_nome" id="cliente_nome" value={data.cliente_nome}
//                                            onChange={handleChange}/>
//                                 </FormGroup>
//                                 <FormGroup>
//                                     <Label for="indirizzo">Indirizzo</Label>
//                                     <Input type="text" name="indirizzo" id="indirizzo" value={data.indirizzo}
//                                            onChange={handleChange}/>
//                                 </FormGroup>
//                                 <FormGroup>
//                                     <Label for="CAP">CAP</Label>
//                                     <Input type="text" name="CAP" id="CAP" value={data.CAP} onChange={handleChange}/>
//                                 </FormGroup>
//                                 <FormGroup>
//                                     <Label for="cod_fiscale">Cod. Fiscale</Label>
//                                     <Input type="text" name="cod_fiscale" id="cod_fiscale" value={data.cod_fiscale}
//                                            onChange={handleChange}/>
//                                 </FormGroup>
//                                 <FormGroup>
//                                     <Label for="part_iva">Part.Iva</Label>
//                                     <Input type="text" name="part_iva" id="part_iva" value={data.part_iva}
//                                            onChange={handleChange}/>
//                                 </FormGroup>
//                                 {
//                                     errorMessage && <div className="alert alert-danger mt-2">{errorMessage}</div>
//                                 }
//
//                                 {
//                                     successMessage && <div className="alert alert-success mt-2">{successMessage}</div>
//                                 }
//                                 <Button className="btn btn-info" type="submit">{contentButton}</Button>
//                             </Form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }
//
// export default BillingDataForm;

import React from 'react';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, Field, FormikProps } from 'formik';
import axios from 'axios';

const BillingDataForm = () => {
    const apiUrl = process.env.REACT_APP_API_URL_MONGO;
    const token = process.env.REACT_APP_TOKEN_MONGO;

    const [clients, setClients] = React.useState([]);

    React.useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await axios.get(apiUrl + 'api/list-clienti', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                  if (response.data && Array.isArray(response.data)) {
                    setClients([{ id: 0, nome: "Scegli un cliente", prezzoOrario: 0 }, ...response.data]);
                }
            } catch (error) {
                console.error("Si è verificato un errore durante il recupero dei clienti:", error);
            }
        };

        fetchClients();
    }, [apiUrl, token]);

    const validationSchema = Yup.object().shape({
        clienti_id: Yup.number().required('Cliente is required'),
        indirizzo: Yup.string().required('Address is required'),
        CAP: Yup.string().required('CAP is required'),
        cod_fiscale: Yup.string().required('Codice Fiscale is required'),
        part_iva: Yup.string().required('Partita IVA is required'),
    });

    const handleClientChange = async (e, setFieldValue) => {
        setFieldValue('clienti_id', e.target.value, false);

        const selectedClientId = e.target.value;

        if (selectedClientId === '0') {
            setFieldValue('cliente_nome', '', false);
            setFieldValue('indirizzo', '', false);
            setFieldValue('CAP', '', false);
            setFieldValue('cod_fiscale', '', false);
            setFieldValue('part_iva', '', false);
            return;
        }

        try {
            const response = await axios.post(apiUrl + `api/billing-cliente`, { id: selectedClientId }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data) {
                setFieldValue('cliente_nome', response.data.cliente_nome || '', false);
                setFieldValue('indirizzo', response.data.indirizzo || '', false);
                setFieldValue('CAP', response.data.CAP || '', false);
                setFieldValue('cod_fiscale', response.data.cod_fiscale || '', false);
                setFieldValue('part_iva', response.data.part_iva || '', false);
            } else {
                setFieldValue('cliente_nome', '', false);
                setFieldValue('indirizzo', '', false);
                setFieldValue('CAP', '', false);
                setFieldValue('cod_fiscale', '', false);
                setFieldValue('part_iva', '', false);
            }

        } catch (error) {
            console.error("Errore nel recupero delle informazioni del cliente:", error.response.data);
        }
    };

    const handleSubmit = async (values, { setSubmitting, setErrors, setStatus }) => {
        try {
            await axios.post(apiUrl + `api/store-billing-cliente`, values, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            setStatus({ success: 'Cliente salvato correttamente' });
            setErrors(null);
        } catch (error) {
            console.error("Errore nel salvataggio del cliente:", error.response.data);

            if (error.response.data.errors) {
                setErrors(error.response.data.errors);
            } else {
                setErrors({ form: error.response.data.message });
            }

            setStatus({});
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="container-fluid mt-4">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <Formik
                                initialValues={{
                                    clienti_id: 0,
                                    cliente_nome: '',
                                    indirizzo: '',
                                    CAP: '',
                                    cod_fiscale: '',
                                    part_iva: '',
                                }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values,
                                      isSubmitting,
                                      touched,
                                      errors,
                                      handleBlur,
                                      handleChange,
                                      setFieldValue,
                                      setFieldTouched
                                  }:
                                      FormikProps<typeof values>) => (
                                    <Form>
                                        <div className="bg-light">
                                            <FormGroup className="w-50 mx-auto p-3">
                                                <Label for="clienti_id">Cliente</Label>
                                                <Field
                                                    name="clienti_id"
                                                    component="select"
                                                    onChange={(e) => handleClientChange(e, setFieldValue)}
                                                    onBlur={handleBlur}
                                                    className={errors.clienti_id && touched.clienti_id ? 'form-control is-invalid' : 'form-control'}
                                                >
                                                    {clients.map(cliente => (
                                                        <option key={cliente.id} value={cliente.id}>
                                                            {cliente.nome}
                                                        </option>
                                                    ))}
                                                </Field>
                                                {errors.clienti_id && touched.clienti_id && <div className="invalid-feedback">{errors.clienti_id}</div>}
                                            </FormGroup>
                                        </div>
                                        <hr/>
                                        <Field
                                            name="cliente_nome"
                                            component="input"
                                            type="hidden"
                                            onBlur={handleBlur}
                                        />
                                        <FormGroup>
                                            <Label for="indirizzo">Indirizzo</Label>
                                            <Field
                                                name="indirizzo"
                                                component="input"
                                                type="text"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                className={errors.indirizzo && touched.indirizzo ? 'form-control is-invalid' : 'form-control'}
                                            />
                                            {errors.indirizzo && touched.indirizzo && <div className="invalid-feedback">{errors.indirizzo}</div>}
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="CAP">CAP</Label>
                                            <Field
                                                name="CAP"
                                                component="input"
                                                type="text"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                className={errors.CAP && touched.CAP ? 'form-control is-invalid' : 'form-control'}
                                            />
                                            {errors.CAP && touched.CAP && <div className="invalid-feedback">{errors.CAP}</div>}
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="cod_fiscale">Cod. Fiscale</Label>
                                            <Field
                                                name="cod_fiscale"
                                                component="input"
                                                type="text"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                className={errors.cod_fiscale && touched.cod_fiscale ? 'form-control is-invalid' : 'form-control'}
                                            />
                                            {errors.cod_fiscale && touched.cod_fiscale && <div className="invalid-feedback">{errors.cod_fiscale}</div>}
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="part_iva">Part.Iva</Label>
                                            <Field
                                                name="part_iva"
                                                component="input"
                                                type="text"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                className={errors.part_iva && touched.part_iva ? 'form-control is-invalid' : 'form-control'}
                                            />
                                            {errors.part_iva && touched.part_iva && <div className="invalid-feedback">{errors.part_iva}</div>}
                                        </FormGroup>
                                        {errors.form && <div className="text-danger">{errors.form}</div>}
                                        <Button
                                            className="btn btn-info"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            Salva
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BillingDataForm;

import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Button, Form, FormGroup, Input, Label} from "reactstrap";
import {Link, useParams} from "react-router-dom";

function ModificaCliente() { // Assuming you pass clienteId as a prop

    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [prezzoOrario, setPrezzoOrario] = useState('');
    const [telefono, setTelefono] = useState('');

    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");

    const apiUrl = process.env.REACT_APP_API_URL_MONGO;
    const token = process.env.REACT_APP_TOKEN_MONGO;

    const {id} = useParams();

    useEffect(() => {
        const fetchCliente = async () => {
            try {
                const response = await axios.get(`${apiUrl}api/cliente/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                const cliente = response.data;
                setNome(cliente.nome ?? '');
                setEmail(cliente.email ?? '');
                setTelefono(cliente.telefono ?? '');
                setPrezzoOrario(cliente.prezzo_orario ?? '');

            } catch (error) {
                console.error("Errore nel recupero delle informazioni del cliente:", error.response.data);
            }
        };

        fetchCliente();
    }, [apiUrl, token, id]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            id: id,
            nome: nome,
            email: email,
            telefono: telefono,
            prezzo_orario: prezzoOrario
        };

        try {
            const response = await axios.post(apiUrl + `api/update-clienti`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            // Estrai i dati dalla risposta
            const {status, message} = response;

            if (status === 'success') {
                setSuccessMessage(message);
                setTimeout(() => {
                    setSuccessMessage('');
                }, 3000);
            } else {
                setErrorMessage(message);
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000);
            }
        } catch (error) {
            setErrorMessage(error.message || "Si è verificato un errore durante l'aggiornamento dei dati.");
        }
    };

    return (
        <div className="container-fluid mt-4">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">Modifcia Cliente</div>
                        <div className="card-body">

                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    {/*<Label for="id">Id</Label>*/}
                                    <Input type="hidden" name="id" id="id" value={id}
                                           onChange={e => setNome(e.target.value)} required={true}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="nome">Nome</Label>
                                    <Input type="text" name="nome" id="nome" value={nome}
                                           onChange={e => setNome(e.target.value)} required={true}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input type="email" name="email" id="email" value={email}
                                           onChange={e => setEmail(e.target.value)} required={true}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">Telefono</Label>
                                    <Input type="text" name="telefono" id="telefono" value={telefono}
                                           onChange={e => setTelefono(e.target.value)} required={true}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="prezzo_orario">Prezzo Orario (€)</Label>
                                    <Input type="number" step="0.01" name="prezzo_orario" id="prezzo_orario"
                                           value={prezzoOrario} onChange={e => setPrezzoOrario(e.target.value)}/>
                                </FormGroup>
                                {
                                    errorMessage && <div className="alert alert-danger mt-2">{errorMessage}</div>
                                }

                                {
                                    successMessage && <div className="alert alert-success mt-2">{successMessage}</div>
                                }
                                <Button color="success" className="mt-2" type="submit">Modifica Cliente</Button>
                                <Link to="/clienti">
                                    <Button color="secondary" className="mt-2 button-spacing">
                                        Torna alla lista interventi</Button>
                                </Link>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModificaCliente;

import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import {Button, Card, CardBody, CardTitle, Container} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Document, Page, pdfjs} from "react-pdf";
import logoImage from '../../assets/images/logo 2023_ver2.png';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';


pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Fattura = () => {

    const apiUrl = process.env.REACT_APP_API_URL_MONGO;
    const token = process.env.REACT_APP_TOKEN_MONGO;

    const [dataCliente, setCliente] = useState(null);
    const [datiFattura, setFattura] = useState({});

    const [numeroFattura, setNumeroFattura] = useState('');
    const [dataFattura, setDataFattura] = useState('');
    const [dataRiferimenti, setDataRiferimenti] = useState('');
    const [importoSconto, setImportoSconto] = useState(0);
    const [anticipazioniAbbuoni, setAnticipazioniAbbuoni] = useState(0);

    const [pdfBlob, setPdfBlob] = useState([]);

    const [errorMessage, setErrorMessage] = useState("");

    const [isInvoiceCreated, setIsInvoiceCreated] = useState(false);


    const {id, id_cliente} = useParams();

    useEffect(() => {
        fetchDataCliente();
        fetchDatiFattura();

    }, []);


    const fetchDataCliente = () => {

        try {

            const formData = {id: id_cliente};
            axios.post(apiUrl + 'api/billing-cliente', formData, {

                headers: {'Authorization': `Bearer ${token}`}

            }).then((response) => {

                // se response message è uguale a "Dati di fatturazione non trovati" mandare un messaggio di errore alla card Impostazioni fattura

                if (response.status === "error") {

                    setErrorMessage("Dati di fatturazione non trovati, impossibile generare la fattura.");

                } else {

                    const datiPuliti = (({__v, createdAt, updatedAt, _id, ...resto}) => resto)(response.data);

                    setCliente(datiPuliti);
                    setErrorMessage("");
                }
            });

        } catch (error) {
            console.error("Errore du rante il caricamento dei dati:", error);
        }

    }

    const fetchDatiFattura = () => {
        try {
            const idInterventi = {id_intervento: id};

            axios.post(`${apiUrl}api/crea-fattura`, idInterventi, {

                headers: {'Authorization': `Bearer ${token}`}

            }).then((response) => {

                setFattura(response.data);

            });

        } catch (error) {

            console.error("Errore durante il caricamento dei dati:", error);
        }
    };

    function handleGeneratePdf(e) {
        e.preventDefault();

        // Controlla se numeroFattura o dataFattura sono vuoti
        if (!numeroFattura || !dataFattura) {
            setErrorMessage("Errore: Numero Fattura e Data Fattura sono campi obbligatori.");
            return; // Interrompe l'esecuzione ulteriore della funzione
        }

        const tableBody = generateTableBody(datiFattura);
        const compilaFattura = datiRenderFattura(datiFattura);
        fetchAndSetBlob(tableBody, compilaFattura);
        setIsInvoiceCreated(true);

        // Resetta il messaggio di errore (se tutto va bene)
        setErrorMessage("");

    }

    const sendPdfToServer = (pdfBlob, namePDF) => {
        const formData = new FormData();
        formData.append('file', pdfBlob, namePDF);



        axios.post(apiUrl + 'api/upload-invoice', formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                // console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }

    const datiRenderFattura = (dati) => {

        let totale = dati.quantita * dati.prezzo;

        // Aggiungi i costi aggiuntivi
        dati.costi_aggiuntivi.forEach(costoAggiuntivo => {
            totale += costoAggiuntivo.quantita * Number(costoAggiuntivo.prezzo);
        });

        const totaleSenzaImposta = totale;
        const percentualeImposta = 4;
        const imposta = totaleSenzaImposta * (percentualeImposta / 100);
        const imponibile = Number(totaleSenzaImposta - importoSconto);
        const totaleDef = Number(imponibile) + Number(imposta) + Number(anticipazioniAbbuoni);


        // Ritorna un oggetto con i dati della fattura
        return {
            totaleFatt: totaleSenzaImposta.toLocaleString('it-IT', {style: 'currency', currency: 'EUR'}),
            imponibile: imponibile.toLocaleString('it-IT', {style: 'currency', currency: 'EUR'}),
            imposta: imposta.toLocaleString('it-IT', {style: 'currency', currency: 'EUR'}),
            totaleDef: totaleDef.toLocaleString('it-IT', {style: 'currency', currency: 'EUR'}),
        };

    }
    const generateTableBody = (datiFattura) => {

        const formatCurrency = (value) => {
            return new Intl.NumberFormat('it-IT', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
            }).format(value);
        };

        const body = [];
// Aggiungi la prima riga vuota
        body.push(new Array(4).fill({text: ' ', style: 'tableBody'}));

// Aggiungi la riga per la descrizione principale
        body.push([
            {text: datiFattura.descrizione, style: 'tableBody'},
            {text: datiFattura.quantita.toString(), style: 'tableBody'},
            {text: formatCurrency(datiFattura.prezzo), style: 'tableBody'},
            {text: formatCurrency(datiFattura.quantita * datiFattura.prezzo), style: 'tableBody'}
        ]);

// Aggiungi le righe per i costi aggiuntivi
        datiFattura.costi_aggiuntivi.forEach(costo => {
            const costoTotale = costo.quantita * parseFloat(costo.prezzo);
            body.push([
                {text: costo.descrizione, style: 'tableBody'},
                {text: costo.quantita.toString(), style: 'tableBody'},
                {text: formatCurrency(costo.prezzo), style: 'tableBody'},
                {text: formatCurrency(costoTotale), style: 'tableBody'}
            ]);
        });

// Aggiungi righe vuote fino a raggiungere il numero di righe desiderato
        while (body.length < 18) {
            body.push(new Array(4).fill({text: ' ', style: 'tableBody'}));
        }

        return body;
    }
    const fetchAndSetBlob = async (tableBody, compilaFattura) => {

        let partiData = dataFattura.split('-');
        let dataFatturaStampa = partiData[2] + '-' + partiData[1] + '-' + partiData[0];
        // const tableBody = tableBodyList;
        const logoDataURL = await getBase64Image(logoImage);
        const docDefinition = {
            pageMargins: [40, 5, 40, 30], // [left, top, right, bottom]
            content: [{
                text: process.env.REACT_APP_RAGIONE_SOCIALE,
                fontSize: 18,
                bold: true,
                alignment: 'center',
                margin: [0, 0, 0, 10]
            },
                {
                    text: `${process.env.REACT_APP_INDIRIZZO} - ${process.env.REACT_APP_CITTA} - ${process.env.REACT_APP_CAP} ${process.env.REACT_APP_PROVINCIA}`,
                    fontSize: 11,
                    alignment: 'center',
                    margin: [0, 0, 0, 6]
                },
                {
                    text: `Cod. Fiscale: ${process.env.REACT_APP_CF}`,
                    fontSize: 11,
                    alignment: 'center',
                    margin: [0, 0, 0, 6]
                },
                {
                    text: `Part.Iva: ${process.env.REACT_APP_PIVA}`,
                    fontSize: 11,
                    alignment: 'center',
                    margin: [0, 0, 0, 6]
                },
                {
                    layout: 'noBorders',
                    table: {
                        widths: [249, '*'],
                        body: [
                            [
                                {
                                    image: logoDataURL,
                                    fit: [170, 170],
                                    alignment: 'center',
                                    margin: [0, 35, 0, 0],
                                },
                                {
                                    layout: {
                                        hLineWidth: function (i, node) {
                                            return 0.5;
                                        },
                                        vLineWidth: function (i, node) {
                                            return 0.5;
                                        },
                                        hLineColor: function (i, node) {
                                            return '#ccc';
                                        },
                                        vLineColor: function (i, node) {
                                            return '#ccc';
                                        }
                                    },
                                    table: {
                                        widths: [100, '*'],
                                        body: [
                                            [{text: 'Tipo Documento:', style: 'column2Style'}, {
                                                text: 'Fattura',
                                                style: 'column2Style'
                                            }],
                                            [{text: 'Numero:', style: 'column2Style'}, {
                                                text: numeroFattura,
                                                style: 'column2Style'
                                            }],
                                            [{text: 'Data:', style: 'column2Style'}, {
                                                text: dataFatturaStampa,
                                                style: 'column2Style'
                                            }],
                                            [{text: 'Riferimenti:', style: 'column2Style'}, {
                                                text: dataRiferimenti,
                                                style: 'column2Style'
                                            }],
                                            [{text: '', style: 'column2Style'}, {text: '', style: 'column2Style'}],
                                            [{text: 'Cliente:', style: 'column2Style'}, {
                                                text: dataCliente.cliente_nome,
                                                style: 'column2Style'
                                            }],
                                            [{text: 'Indirizzo:', style: 'column2Style'}, {
                                                text: dataCliente.indirizzo,
                                                style: 'column2Style'
                                            }],
                                            [{text: 'CAP:', style: 'column2Style'}, {
                                                text: dataCliente.CAP,
                                                style: 'column2Style'
                                            }],
                                            [{text: 'Cod. Fiscale:', style: 'column2Style'}, {
                                                text: dataCliente.cod_fiscale,
                                                style: 'column2Style'
                                            }],
                                            [{text: 'Part.Iva:', style: 'column2Style'}, {
                                                text: dataCliente.part_iva,
                                                style: 'column2Style'
                                            }]
                                        ]
                                    }
                                }
                            ]
                        ]
                    }
                },
                {
                    layout: {
                        hLineWidth: function (i, node) {
                            return 0.5;
                        },
                        vLineWidth: function (i, node) {
                            return 0.5;
                        },
                        hLineColor: function (i, node) {
                            return '#ccc';
                        },
                        vLineColor: function (i, node) {
                            return '#ccc';
                        }
                    },
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                {
                                    text: 'Condizioni Pagamento:',
                                    bold: true,
                                    style: 'column1Style'
                                },
                                {
                                    text: 'Bonifico Bancario',
                                    bold: true,
                                    style: 'column1Style'
                                }
                            ]
                        ]
                    }
                },
                {
                    layout: {
                        hLineWidth: function (i, node) {
                            return 0.5;
                        },
                        vLineWidth: function (i, node) {
                            return 0.5;
                        },
                        hLineColor: function (i, node) {
                            return '#ccc';
                        },
                        vLineColor: function (i, node) {
                            return '#ccc';
                        }
                    },
                    table: {
                        widths: [100, '*', 110, 110],
                        body: [
                            [
                                {text: 'Presso:', bold: true, style: 'column1Style'},
                                {text: process.env.REACT_APP_BANCA, style: 'column1Style'},
                                {text: 'C/C:', bold: true, style: 'column1Style'},
                                {text: '', style: 'column1Style'}
                            ],
                            [
                                {text: '', bold: true, style: 'column1Style'},
                                {text: process.env.REACT_APP_IBAN, bold: true, style: 'column1Style'},
                                {text: 'CAB:', bold: true, style: 'column1Style'},
                                {text: 'ABI:', bold: true, style: 'column1Style'}
                            ]
                        ]
                    }
                },
                {
                    layout: {
                        hLineWidth: function (i, node) {
                            return 0.5;
                        },
                        vLineWidth: function (i, node) {
                            return 0.5;
                        },
                        hLineColor: function (i, node) {
                            return '#ccc';
                        },
                        vLineColor: function (i, node) {
                            return '#ccc';
                        },
                        fillColor: function (rowIndex, node, columnIndex) {
                            return (rowIndex === 0) ? '#22b3ae' : null;
                        }
                    },
                    table: {
                        headerRows: 1,
                        widths: ['*', 62, 60, 60],
                        body: [
                            // Header
                            [
                                {text: 'DESCRIZIONE', style: 'tableHeader'},
                                {text: 'Q.TA\'/ORE', style: 'tableHeader'},
                                {text: 'IMPORTO', style: 'tableHeader'},
                                {text: 'TOTALE', style: 'tableHeader'}
                            ],
                            // Corpo
                            ...tableBody,
                        ],
                    },
                },
                {
                    table: {
                        widths: ['*'], // Impostare l'ampiezza della colonna
                        body: [
                            [
                                {
                                    text: "Operazione effettuata ai sensi dell'art. 1, comma 587 e 59 della legge di stabilità 2015 (Legge 190/2014). E senza ritenuta d'acconto",
                                    alignment: 'center',
                                    fontSize: 10,
                                    margin: [1, 1, 1, 1]
                                }
                            ]
                        ]
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return 0.5;
                        },
                        vLineWidth: function (i, node) {
                            return 0.5;
                        },
                        hLineColor: function (i, node) {
                            return '#ccc';
                        },
                        vLineColor: function (i, node) {
                            return '#ccc';
                        }
                    }
                },
                {
                    table: {
                        widths: ['*', '15%', '20%', '25%', '15%'],
                        body: [
                            [
                                {text: 'ALIQUOTE', style: 'tableHeader'},
                                {text: 'IMPONIBILI', style: 'tableHeader'},
                                {text: 'DESCRIZIONE', style: 'tableHeader'},
                                {text: 'TOTALE PRESTAZIONI', style: 'tableHeader'},
                                {text: compilaFattura.totaleFatt, style: 'tableHeader'}
                            ],
                            [
                                {text: 'Non soggetta', style: 'bodyCell'},
                                {text: compilaFattura.imponibile, style: 'bodyCell'},
                                {
                                    text: 'Operazione non soggetta ad IVA ( art. 1, comma 100, Legge 24/12/2007, n. 244)',
                                    style: 'bodyCell'
                                },
                                {text: '% DI SCONTO', style: 'bodyCell'},
                                {text: '0,00%', style: 'bodyCell'}
                            ],
                            [
                                {text: '20%', style: 'bodyCell'},
                                {text: '', style: 'bodyCell'},
                                {text: '', style: 'bodyCell'},
                                {text: 'IMPORTO SCONTO', style: 'bodyCell'},
                                {
                                    text: importoSconto.toLocaleString('it-IT', {style: 'currency', currency: 'EUR'}),
                                    style: 'bodyCell'
                                }
                            ],
                            [
                                {text: 'N.I.', style: 'bodyCell'},
                                {text: '-', style: 'bodyCell'},
                                {text: 'Art 15', style: 'bodyCell'},
                                {text: 'IMPONIBILE', style: 'bodyCell'},
                                {text: compilaFattura.imponibile, style: 'bodyCell'}
                            ],
                            [
                                {text: 'ESENTI', style: 'bodyCell'},
                                {text: '-', style: 'bodyCell'},
                                {text: 'Art. 10', style: 'bodyCell'},
                                {text: 'ANTICIPAZIONI/ABBUONI +/-', style: 'bodyCell'},
                                {
                                    text: anticipazioniAbbuoni.toLocaleString('it-IT', {
                                        style: 'currency',
                                        currency: 'EUR'
                                    }), style: 'bodyCell'
                                }
                            ],
                            [
                                {text: 'ESCLUSE', style: 'bodyCell'},
                                {text: '-', style: 'bodyCell'},
                                {text: 'Fuori campo', style: 'bodyCell'},
                                {text: 'CASSA PREVIDENZA 4%', style: 'bodyCell'},
                                {text: compilaFattura.imposta, style: 'bodyCell'}
                            ],
                            [
                                {text: '', style: 'tfootCell'},
                                {text: '', style: 'tfootCell'},
                                {text: '', style: 'tfootCell'},
                                {text: 'TOTALE FATTURA', style: 'tfootCell', alignment: 'right'},
                                {text: compilaFattura.totaleDef, style: 'tfootCell'}
                            ]
                        ]

                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return 0.5;
                        },
                        vLineWidth: function (i, node) {
                            return 0.5;
                        },
                        hLineColor: function (i, node) {
                            return '#ccc';
                        },
                        vLineColor: function (i, node) {
                            return '#ccc';
                        }
                    },
                    style: 'fatturaStyle'
                }
            ],
            styles: {
                fatturaStyle: {
                    fontSize: 10,
                    margin: [0, 2, 0, 3]
                },
                tableHeader: {
                    fontSize: 9,
                    bold: true,
                    color: '#ffffff',
                    fillColor: '#8b32af',
                    alignment: 'center',
                    margin: [9, 1, 9, 2]
                },
                tableBody: {
                    fontSize: 10
                    // Aggiungi altri stili qui se necessario
                },
                bodyCell: {
                    fontSize: 8,
                    alignment: 'center',
                    margin: [10, 1.5, 10, 2]
                },
                tfootCell: {
                    fontSize: 10,
                    color: '#ffffff',
                    fillColor: '#8b32af',
                    bold: true,
                    margin: [10, 1, 10, 2]
                },
                column1Style: {
                    fontSize: 10,
                },
                column2Style: {
                    fontSize: 10,
                }
            }
        };

        pdfMake.createPdf(docDefinition).getBlob(blob => {
            setPdfBlob(blob);
            let {nomeClienteConUnderscore, dataFat, numeroFatturaFormatted} = nomefile();
            const namePDF = `${numeroFatturaFormatted}_${nomeClienteConUnderscore}_${dataFat}.pdf`;
            sendPdfToServer(blob, namePDF);

        });
    }

    function nomefile() {
        let nomeClienteConUnderscore = dataCliente.cliente_nome.replace(/\s/g, '_');
        let partiData = dataFattura.split('-'); // Divide la data in un array [YYYY, MM, DD]
        let dataFat = partiData[2] + '_' + partiData[1] + '_' + partiData[0];
        let numeroFatturaFormatted = numeroFattura;

        if (!isNaN(numeroFattura) && numeroFattura < 10) {
            numeroFatturaFormatted = '0' + numeroFattura;
        }

        return {nomeClienteConUnderscore, dataFat, numeroFatturaFormatted};
    }

    async function getBase64Image(imgUrl) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                const reader = new FileReader();
                reader.onloadend = function () {
                    resolve(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.onerror = function (e) {
                reject(e);
            };
            xhr.open('GET', imgUrl);
            xhr.responseType = 'blob';
            xhr.send();
        });
    }

    function handleDownload(e) {
        e.preventDefault();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(pdfBlob);  // Crea un URL temporaneo dal blob
        let {nomeClienteConUnderscore, dataFat, numeroFatturaFormatted} = nomefile();
        link.download = `${numeroFatturaFormatted}_${nomeClienteConUnderscore}_${dataFat}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    const handleInputChange = (e) => {
        const {name, value} = e.target;
        switch (name) {
            case 'numeroFattura':
                setNumeroFattura(value);
                break;
            case 'dataFattura':
                setDataFattura(value);
                break;
            case 'importoSconto':
                setImportoSconto(value);
                break;
            case 'anticipazioniAbbuoni':
                setAnticipazioniAbbuoni(value);
                break;
            case 'dataRiferimenti':
                setDataRiferimenti(value);
            default:
                break;
        }
    };

    const handleBackToList = () => {
        window.location.href = "/interventi";
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Creazione Fattura" breadcrumbItem="Fattura"/>
                    <div className="row">
                        <div className="col-md-6 ms-5">
                            <div className="pdf-container">
                                {pdfBlob ? (
                                    <Document
                                        file={pdfBlob}
                                        loading={<div className="loading-message">Anteprima non ancora disponibile
                                            premere Genera PDF...</div>}
                                        noData={<div className="loading-message">PDF non disponibile.</div>}
                                        onError={(error) => {
                                            console.error('Error while loading document!', error.message);
                                        }}
                                    >
                                        <Page pageNumber={1}/>
                                    </Document>
                                ) : (
                                    <div className="loading-message">Attendere il caricamento del PDF...</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <Card className="mt-3">
                                <CardBody>
                                    <CardTitle>Impostazioni Fattura</CardTitle>
                                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                    <form>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="numeroFattura">N.Fattura:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="numeroFattura"
                                                        name="numeroFattura"
                                                        value={numeroFattura}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="form-group">
                                                    <label htmlFor="dataFattura">Data Fattura:</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        id="dataFattura"
                                                        name="dataFattura"
                                                        value={dataFattura}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="importoSconto">Riferimenti:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="dataRiferimenti"
                                                name="dataRiferimenti"
                                                value={dataRiferimenti}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="importoSconto">Importo Sconto:</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="importoSconto"
                                                        name="importoSconto"
                                                        value={importoSconto}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="anticipazioniAbbuoni">Anticipazioni/Abbuoni:</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="anticipazioniAbbuoni"
                                                        name="anticipazioniAbbuoni"
                                                        value={anticipazioniAbbuoni}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" className="btn btn-primary mt-2 me-2"
                                                onClick={handleGeneratePdf}>Genera PDF
                                        </button>
                                        <button onClick={handleDownload}
                                                className={`btn btn-primary mt-2 me-2 ${!isInvoiceCreated ? 'disabled' : ''}`}>Scarica
                                        </button>
                                        <Button color="secondary" className="mt-2" onClick={handleBackToList}>Torna alla
                                            lista interventi</Button>
                                    </form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Container>

            </div>
        </React.Fragment>
    );
};

export default Fattura;
import React, {useState, useEffect} from 'react';
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import BoxListIntervSpeseMaterial from "./Sezione_Interv_spese_materiale/BoxListIntervSpeseMaterial";
import ModalInsInterventi from "./Modals/ModalInsInterventi";
import ModalInsSpeseMateriali from "./Modals/ModalInsSpeseMateriali";
import {intervOptions, services} from "./helper/arrayList";



const ModificaInter = () => {

    const apiUrl = process.env.REACT_APP_API_URL_MONGO;
    const token = process.env.REACT_APP_TOKEN_MONGO;

    // Inserimento costi aggiuntivi
    const [modalOpen, setModalOpen] = useState(false);
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [quantity, setQuantity] = useState('');
    const [additionalCosts, setAdditionalCosts] = useState([]);

    // Interventi fatti
    const [modalOpenInt, setModalOpenInt] = useState(false);
    const [descriptionInt, setDescriptionInt] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [hourlyRate, setHourlyRate] = useState('');
    const [totalCost, setTotalCost] = useState('');
    const [timeDifference, setTimeDifference] = useState('');
    const [interventionsDone, setInterventionsDone] = useState([]);

    // Campi Form
    const [clienteId, setClienteId] = useState(0);
    const [clienteSelezionatoId, setClienteSelezionatoId] = useState('');
    const [oreLavorate, setOreLavorate] = useState(0);
    const [dataInizio, setDataInizio] = useState('');
    const [dataFine, setDataFine] = useState('');
    const [stato, setStato] = useState('');
    const [descrizione, setDescrizione] = useState('');
    const [fatturato, setFatturato] = useState(null);
    const [prezzoOrario, setPrezzoOrario] = useState(0);
    const [clienti, setClienti] = useState([]);
    const [idIntervento, setIdIntervento] = useState(0);

    const [selectedServiceId, setSelectedServiceId] = useState('');


    // Set error Message
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");
    const [clienteIdError, setClienteIdError] = useState(false);
    const [dataInizioError, setDataInizioError] = useState(false);
    const [oreLavorateError, setOreLavorateError] = useState(false);
    const [statoError, setStatoError] = useState(false);


    const {id} = useParams();

    useEffect(() => {
        const fetchClienti = async () => {
            try {
                const response = await axios.get(apiUrl + 'api/list-clienti', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.data && Array.isArray(response.data)) {

                    setClienti([{id: 0, nome: "Scegli un cliente", prezzoOrario: 0}, ...response.data]);

                }
            } catch (error) {
                console.error("Si è verificato un errore durante il recupero dei clienti:", error);
            }
        };

        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}api/interventi/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });


                const data = response.data;

                if (data) {
                    setIdIntervento(data.id)
                    setClienteId(data.cliente_id);
                    setOreLavorate(data.ore_lavorate);
                    setDataInizio(data.data_inizio.split('T')[0]);
                    setDataFine(data.data_fine.split('T')[0]);
                    setStato(data.stato);
                    setDescrizione(data.descrizione);
                    setFatturato(data.fatturato);
                    setSelectedServiceId(data.tipo_intervento);
                    setPrezzoOrario(data.prezzo_orario);
                    setAdditionalCosts(data.costi_aggiuntivi);
                    // Map data.type_interventions into a new array with renamed property keys
                    const reformattedInterventions = data.type_interventi.map(item => ({
                        description: item.description,
                        startTime: item.start_time,
                        endTime: item.end_time,
                        totalHours: item.total_hours,
                        hourlyRate: item.hourly_rate,
                        totalCost: item.total_cost,
                    }));

                    setInterventionsDone(reformattedInterventions);
                    setClienteSelezionatoId(data.cliente_id);
                }

                console.log(data)

            } catch (error) {
                console.error("Error fetching the intervention data:", error);
            }
        };

        fetchData().then(r => console.log("Intervento caricato"));
        fetchClienti().then(r => console.log("Clienti caricati"));
    }, [apiUrl, token, id]);

    // Function to calculate the time difference and the total cost
    const  calculateDiffAndCost = () => {
        let start = new Date(`01/01/2022 ${startTime}`);
        let end = new Date(`01/01/2022 ${endTime}`);

        // Calculate difference in hours
        let timeDifference = (end - start) / (1000 * 60 * 60);
        // Calculate Total cost
        let totalCost = timeDifference * hourlyRate;
        return {timeDifference, totalCost};
    };

    useEffect(() => {
        const calculationResult = calculateDiffAndCost();
        setTimeDifference(calculationResult.timeDifference);
        setTotalCost(calculationResult.totalCost);
    }, [hourlyRate, startTime, endTime]);


    const handleClienteChange = (e) => {
        setClienteId(e.target.value);
        const selectedClienteID = e.target.value;
        const selectedCliente = clienti.find(cliente => cliente.id.toString() === selectedClienteID);
        setClienteSelezionatoId(selectedCliente.id);
    }

    const handleDataInizioChange = (e) => {
        const selectedDataInizio = e.target.value;

        // Imposta il valore di dataInizio
        setDataInizio(selectedDataInizio);

        // Imposta il valore di dataFine uguale a dataInizio
        setDataFine(selectedDataInizio);
    }

    // La funzione per aggiungere un nuovo intervento
    const addIntervention = (intervention) => {
        setInterventionsDone(prev => [...prev, intervention]);
    }

    // Rimuovere un intervento
    const removeIntervention = (index) => {
        setInterventionsDone(interventionsDone.filter((_, i) => i !== index));
    }



    const handleOreLavorateChange = (e) => {
        setOreLavorate(e.target.value);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (clienteId === 0 || !dataInizio || !oreLavorate || stato === '') {
            setErrorMessage("Per favore, compila tutti i campi obbligatori.");

            // Aggiorna gli stati di errore
            setClienteIdError(clienteId === 0);
            setDataInizioError(!dataInizio);
            setOreLavorateError(!oreLavorate);
            setStatoError(stato === '');
            return;
        } else {

            setErrorMessage(null);
            setSuccessMessage(null);
            setClienteIdError(false);
            setDataInizioError(false);
            setOreLavorateError(false);
            setStatoError(false);
        }

        const formData = {
            id_intervento: idIntervento,
            cliente_id: clienteId,
            ore_lavorate: oreLavorate,
            data_inizio: dataInizio,
            data_fine: dataFine,
            stato: stato,
            descrizione: descrizione,
            fatturato: fatturato,
            tipo_intervento: selectedServiceId,
            prezzo_orario: prezzoOrario,
            costi_aggiuntivi: additionalCosts,
            interventi_fatti: interventionsDone
        };

        try {
            const response = await axios.post(apiUrl + 'api/update-intervento', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            // Estrai i dati dalla risposta
            const {status, message} = response;

            if (status === 'success') {
                setSuccessMessage(message);
            } else {
                setErrorMessage(message);
            }

        } catch (error) {
            setErrorMessage(error.message || "Si è verificato un errore durante l'aggiornamento dei dati.");
        }
    };

    const saveCost = () => {
        // Aggiungi il nuovo costo all'elenco dei costi aggiuntivi
        const newCost = {
            description: description,
            quantity: quantity,
            amount: amount
        };

        setAdditionalCosts(prevCosts => [...prevCosts, newCost]);
        setDescription('');
        setAmount('');

        setModalOpen(false);
    };

    const handleCheckFatturato = (e) => {
        setFatturato(e.target.checked);
    };

    const handleprezzoOrarioChange = (e) => {
        setPrezzoOrario(e.target.value);
    }

    const removeCostRow = (index) => {
        const newCosts = [...additionalCosts];
        newCosts.splice(index, 1);
        setAdditionalCosts(newCosts);
    };

    const handleServiceChange = (event) => {
        setSelectedServiceId(event.target.value);
    };

    const selectedService = services.find(service => service.id.toString() === selectedServiceId);


    return (
        <div className="container-fluid mt-4">
            <div className="row justify-content-center">
                <div className="col-md-10">
                    <div className="card">
                        <div className="card-header">Modifica dati Intervento</div>

                        <div className="card-body">

                            <Form onSubmit={handleSubmit}>

                                <div className="row">
                                    <div className="col-md-4">
                                        <FormGroup>
                                            <Input
                                                type="hidden"
                                                className="form-control"
                                                name="ore_lavorate"
                                                id="ore_lavorate"
                                                required
                                                value={idIntervento}

                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="cliente_id">Cliente</Label>
                                            <Input
                                                type="select"
                                                name="cliente_id"
                                                id="cliente_id"
                                                value={clienteSelezionatoId}
                                                style={clienteIdError ? {border: '1px solid red'} : {}}
                                                onChange={handleClienteChange}>
                                                {clienti.map(cliente => (
                                                    <option key={cliente.id} value={cliente.id}>
                                                        {cliente.nome}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>

                                        <FormGroup className="service-select-form-group">
                                            <Label for="service_id" className="service-select-label">Tipo di
                                                Intervento</Label>
                                            <Input type="select"
                                                   name="service_id"
                                                   id="service_id"
                                                   value={selectedServiceId}
                                                   onChange={handleServiceChange}
                                                   className="service-select-input">
                                                <option value="">--Seleziona un'opzione--</option>
                                                {services.map(service => (
                                                    <option key={service.id}
                                                            value={service.id}>{service.key.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</option>
                                                ))}
                                            </Input>
                                            {selectedService && <div className="service-description" style={{
                                                marginTop: '10px',
                                                color: '#0b53b1'
                                            }}>{selectedService.description}</div>}
                                        </FormGroup>

                                        <FormGroup className="mt-3">
                                            <Label for="descrizione">Descrizione</Label>
                                            <Input type="textarea" name="descrizione" id="descrizione"
                                                   value={descrizione}
                                                   onChange={(e) => setDescrizione(e.target.value)}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="ore_lavorate">Ore Lavorate/Previste</Label>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                name="ore_lavorate"
                                                id="ore_lavorate"
                                                step="0.1"
                                                required
                                                value={oreLavorate}
                                                style={oreLavorateError ? {border: '1px solid red'} : {}}
                                                onChange={handleOreLavorateChange}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="dataInizio">Data Inizio</Label>
                                            <Input
                                                type="date"
                                                name="dataInizio"
                                                id="dataInizio"
                                                value={dataInizio}
                                                style={dataInizioError ? {border: '1px solid red'} : {}}
                                                onChange={handleDataInizioChange}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="dataFine">Data Fine</Label>
                                            <Input type="date" name="dataFine" id="dataFine" value={dataFine}
                                                   onChange={(e) => setDataFine(e.target.value)}/>
                                        </FormGroup>


                                        <FormGroup>
                                            <Label for="stato">Stato</Label>
                                            <Input
                                                type="select"
                                                name="stato"
                                                id="stato"
                                                value={stato}
                                                style={statoError ? {border: '1px solid red'} : {}}
                                                onChange={(e) => setStato(e.target.value)}>
                                                <option value="">Scegli uno Stato</option>
                                                <option value="programmato">Programmato</option>
                                                <option value="in_corso">In corso</option>
                                                <option value="completato">Completato</option>
                                                <option value="cancellato">Cancellato</option>
                                            </Input>
                                        </FormGroup>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <FormGroup>
                                                    <Label>Prezzo
                                                        Orario:</Label> {/* Visualizza il prezzo di default */}
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">€</span>
                                                        </div>
                                                        <Input type="number"
                                                               name="prezzo_orario"
                                                               id="prezzoOrarioInput"
                                                               value={prezzoOrario}
                                                               onChange={handleprezzoOrarioChange}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </div>

                                        <FormGroup check className="mb-3">
                                            <Input
                                                type="checkbox"
                                                name="fatturato"
                                                id="fatturato"
                                                defaultChecked={fatturato}
                                                onClick={(e) => handleCheckFatturato(e)}
                                            />
                                            Fatturato

                                        </FormGroup>
                                    </div>

                                    <div className="col-md-8">
                                        <h4>Costi Aggiuntivi</h4>
                                        <BoxListIntervSpeseMaterial onClick={() => setModalOpenInt(true)}
                                                                    interventionsDone={interventionsDone}
                                                                    callbackfn={(intervention, index) => (
                                                                        <tr key={index}>
                                                                            <td>{intervention.description}</td>
                                                                            <td>{intervention.startTime}</td>
                                                                            <td>{intervention.endTime}</td>
                                                                            <td>{intervention.totalHours}</td>
                                                                            <td>{intervention.hourlyRate}</td>
                                                                            <td>{intervention.totalCost}</td>
                                                                            <td>
                                                                                <Button color="danger" size="sm"
                                                                                        onClick={() => removeIntervention(index)}>Elimina</Button>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                    callbackfn1={(prev, cur) => prev + (cur.totalHours)}
                                                                    callbackfn2={(prev, cur) => prev + (cur.totalCost)}
                                                                    onClick1={() => setModalOpen(true)}
                                                                    additionalCosts={additionalCosts}
                                                                    callbackfn3={(cost, index) => (
                                                                        <tr key={index}>
                                                                            <td>{cost.description}</td>
                                                                            <td>{cost.quantity}</td>
                                                                            <td>{cost.amount} €</td>
                                                                            <td>{cost.quantity * cost.amount} €</td>
                                                                            <td>
                                                                                <Button color="danger" size="sm"
                                                                                        onClick={() => removeCostRow(index)}>Elimina</Button>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                    callbackfn4={(prev, cur) => prev + (cur.quantity * cur.amount)}/>
                                    </div>
                                </div>
                                {
                                    errorMessage && <div className="alert alert-danger mt-2">{errorMessage}</div>
                                }

                                {
                                    successMessage && <div className="alert alert-success mt-2">{successMessage}</div>
                                }
                                <Button color="success" className="mt-2">Modifica Intervento</Button>
                                <Link to="/interventi">
                                    <Button className="btn btn-secondary mt-2 button-spacing">
                                        Torna alla lista interventi
                                    </Button>
                                </Link>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

            {/*Modal Inserimento Interventi fatti*/}
            <ModalInsInterventi open={modalOpenInt} toggle={() => setModalOpenInt(!modalOpenInt)} value={descriptionInt}
                                onChange={e => setDescriptionInt(e.target.value)} intervOptions={intervOptions}
                                callbackfn={(option, index) => (
                                    <option key={option.id} value={option.key}>
                                        {option.key}
                                    </option>
                                )} value1={startTime} onChange1={e => setStartTime(e.target.value)} value2={endTime}
                                onChange2={e => setEndTime(e.target.value)} value3={hourlyRate}
                                onChange3={e => setHourlyRate(e.target.value)} value4={timeDifference}
                                value5={totalCost} onClick={() => setModalOpenInt(false)} onClick1={() => {
                addIntervention({
                    description: descriptionInt,
                    startTime: startTime,
                    endTime: endTime,
                    hourlyRate: hourlyRate,
                    totalHours: calculateDiffAndCost().timeDifference,
                    totalCost: calculateDiffAndCost().totalCost
                });
                setEndTime('')
                setDescriptionInt('')
                setStartTime('')
                setHourlyRate('')
                setTimeDifference('')
                setTotalCost('')
                setModalOpenInt(false);
            }}/>

            {/*Modal Inserimento Costi Aggiuntivi*/}
            <ModalInsSpeseMateriali open={modalOpen} toggle={() => setModalOpen(!modalOpen)} value={description}
                                    onChange={e => setDescription(e.target.value)} value1={quantity}
                                    onChange1={e => setQuantity(e.target.value)} value2={amount}
                                    onChange2={e => setAmount(e.target.value)} onClick={() => setModalOpen(false)}
                                    onClick1={saveCost}/>
        </div>
    );
};

export default ModificaInter;

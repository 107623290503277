import React from "react";
import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";

class ModalInsInterventi extends React.Component<{
    open: boolean,
    toggle: () => void,
    value: string,
    onChange: (e) => void,
    intervOptions: [{ rangeDate: boolean, id: number, key: string }, { rangeDate: boolean, id: number, key: string }, {
        rangeDate: boolean,
        id: number,
        key: string
    }],
    callbackfn: (option, index) => any,
    value1: string,
    onChange1: (e) => void,
    value2: string,
    onChange2: (e) => void,
    value3: string,
    onChange3: (e) => void,
    value4: string,
    value5: string,
    onClick: () => void,
    onClick1: (() => void) | any
}> {
    render() {
        return <Modal isOpen={this.props.open} toggle={this.props.toggle}>
            <ModalHeader toggle={this.props.toggle}>Aggiungi Costo Orario</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="descrizioneCosto">Descrizione</Label>
                    <Input type="select" id="descrizioneCosto" value={this.props.value}
                           onChange={this.props.onChange}>
                        {this.props.intervOptions.map(this.props.callbackfn)}
                    </Input>
                </FormGroup>
                <>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="startTime">Ora inizio</Label>
                                <Input type="time" id="startTime" value={this.props.value1}
                                       onChange={this.props.onChange1}/>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="endTime">Ora fine</Label>
                                <Input type="time" id="endTime" value={this.props.value2}
                                       onChange={this.props.onChange2}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </>

                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="hourlyRate">Costo orario (€)</Label>
                            <Input type="number" step="0.01" id="hourlyRate" value={this.props.value3}
                                   onChange={this.props.onChange3}/>
                        </FormGroup>
                    </Col>

                    <Col md={6}>
                        <FormGroup>
                            <Label for="totalHours">Ore effettive</Label>
                            <Input type="number" step="0.01" id="totalHours" value={this.props.value4} readOnly/>
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <Label for="totalCost">Totale (€)</Label>
                    <Input type="number" step="0.01" id="totalCost" value={this.props.value5} readOnly/>
                </FormGroup>
            </ModalBody>

            <ModalFooter>
                <Button color="secondary" onClick={this.props.onClick}>Annulla</Button>
                <Button color="primary" onClick={this.props.onClick1}>Salva</Button>
            </ModalFooter>
        </Modal>;
    }
}

export default ModalInsInterventi;
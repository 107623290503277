import React from "react";
import {Navigate} from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";
import Interventi from "../Pages/Interventi";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";

// Import Authentication Inner Pages
import Login1 from "../Pages/AuthenticationPages/Login";
import Register1 from "../Pages/AuthenticationPages/Register";
import RecoverPassword from "../Pages/AuthenticationPages/RecoverPassword";
import LockScreen from "../Pages/AuthenticationPages/LockScreen";

// Import Utility Pages
import Maintenance from "../Pages/Utility/Maintenance-Page";
import ComingSoon from "../Pages/Utility/ComingSoon-Page";
import Error404 from "../Pages/Utility/Error404-Page";
import Error500 from "../Pages/Utility/Error500-Page";
import Crea from "../Pages/Interventi/Crea";
import InserisciClienti from "../Pages/Clienti/InserisciClienti";
import Calender from "../Pages/Calendario";
import Modifica from "../Pages/Interventi/Modifica";
import ClientiLista from "../Pages/Clienti";
import ModificaCl from "../Pages/Clienti/modifica";
import Fattura from "../Pages/Fattura/Fattura";
import BillingCl from "../Pages/Clienti/billingClienti";

const authProtectedRoutes = [

    //dashboard
    {path: "/dashboard", component: <Dashboard/>},
    {path: "/", component: <Dashboard/>},


    // Interventi
    {path: "/interventi", component: < Interventi/>},
    {path: "/interventi-crea", component: < Crea/>},
    {path: "/interventi-modifica/:id", component: < Modifica/>},

    // CLienti
    {path: "/clienti-inserisci", component: < InserisciClienti/>},
    {path: "/clienti", component: < ClientiLista/>},
    {path: "/billing-cliente", component: <BillingCl/>},
    {path: "/clienti-modifica/:id", component: < ModificaCl/>},

    // Calendario
    {path: "/calendar", component: <Calender/>},

    // Fattura
    {path: "/fattura/:id/cliente/:id_cliente", component: <Fattura/>},

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    {
      path: "/",
      exact: true,
      component: <Navigate to="/dashboard" />,
    },

] ;

const publicRoutes = [
    // Authentication Page
    {path: "/logout", component: <Logout/>},
    {path: "/login", component: <Login/>},
    {path: "/forgot-password", component: <ForgetPasswordPage/>},
    {path: "/register", component: <Register/>},

    // Authentication Inner Pages
    {path: "/auth-login", component: <Login1/>},
    {path: "/auth-register", component: <Register1/>},
    {path: "/auth-recoverpw", component: <RecoverPassword/>},
    {path: "/auth-lock-screen", component: <LockScreen/>},

    // Utility Pages
    {path: "/pages-404", component: <Error404/>},
    {path: "/pages-500", component: <Error500/>},
    {path: "/pages-maintenance", component: <Maintenance/>},
    {path: "/pages-comingsoon", component: <ComingSoon/>},

];

export {authProtectedRoutes, publicRoutes};

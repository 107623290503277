import React, {useState} from 'react';
import axios from 'axios';
import {Button, Form, FormGroup, Input, Label} from "reactstrap";
import {Link} from "react-router-dom";

function AggiungiCliente() {

    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [prezzoOrario, setPrezzoOrario] = useState('');
    const [telefono, setTelefono] = useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState("");

    const apiUrl = process.env.REACT_APP_API_URL_MONGO;
    const token = process.env.REACT_APP_TOKEN_MONGO;

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            nome: nome,
            email: email,
            telefono: telefono,
            prezzo_orario: prezzoOrario
        };

        try {

            const response = await axios.post(apiUrl + 'api/store-clienti', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const {status, message} = response;

            if (status === 'success') {
                setSuccessMessage(message);
            } else {
                setErrorMessage(message);
            }

        } catch (error) {
            setErrorMessage('Si è verificato un errore durante la richiesta, riprova più tardi.');
            console.error("Si è verificato un errore durante la richiesta:", error.response.data);
        }
    };

    return (
        <div className="container-fluid mt-4">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">Aggiungi Cliente</div>
                        <div className="card-body">

                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label for="nome">Nome</Label>
                                    <Input type="text" name="nome" id="nome" value={nome}
                                           onChange={e => setNome(e.target.value)} required/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input type="email" name="email" id="email" value={email}
                                           onChange={e => setEmail(e.target.value)} required/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">Telefono</Label>
                                    <Input type="text" name="telefono" id="telefono" value={telefono}
                                           onChange={e => setTelefono(e.target.value)} required/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="prezzo_orario">Prezzo Orario (€)</Label>
                                    <Input type="number" step="0.01" name="prezzo_orario" id="prezzo_orario"
                                           value={prezzoOrario} onChange={e => setPrezzoOrario(e.target.value)}/>
                                </FormGroup>
                                {
                                    errorMessage && <div className="alert alert-danger mt-2">{errorMessage}</div>
                                }

                                {
                                    successMessage && <div className="alert alert-success mt-2">{successMessage}</div>
                                }
                                <Button color="success" className="mt-2" type="submit">Salva Cliente</Button>
                                <Link to="/clienti">
                                    <Button color="secondary" className="mt-2 button-spacing">
                                        Torna alla lista Clienti</Button>
                                </Link>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AggiungiCliente;

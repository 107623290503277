import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import {
    Button,
    Card,
    CardBody,
    Col,
    Container, Modal, ModalBody, ModalFooter, ModalHeader,
    Row,
} from "reactstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";

const Calender = (props) => {

    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL_MONGO;
    const token = process.env.REACT_APP_TOKEN_MONGO;

    useEffect(() => {

        axios.get(apiUrl + 'api/eventiCalendar', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            setEvents(response.data);
        });

    }, [apiUrl, token]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    const handleEventClick = ({event}) => {
        setSelectedEvent(event);
        setIsModalOpen(true);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    document.title = "Calendario | Gestione Interventi";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Gestione Interventi" breadcrumbItem="Calendar"/>
                    <Row className="mb-4">

                        <Col xl={12}>
                            <Card className="mb-0">
                                <CardBody>
                                    <FullCalendar
                                        plugins={[dayGridPlugin,]}
                                        handleWindowResize={true}
                                        themeSystem="bootstrap"
                                        headerToolbar={{
                                            left: "prev,next today",
                                            center: "title",
                                            right: "dayGridMonth,dayGridWeek,dayGridDay",
                                        }}
                                        events={events}
                                        eventClick={handleEventClick}
                                    />

                                    <Modal isOpen={isModalOpen} toggle={toggleModal} centered>
                                        <ModalHeader toggle={toggleModal}>Dettagli dell'evento</ModalHeader>
                                        <ModalBody>
                                            {
                                                selectedEvent && (
                                                    <div>
                                                        <p><strong>Titolo:</strong> {selectedEvent.title}</p>
                                                        <p><strong>Data Inizio:</strong> {formatDate(selectedEvent.start)}</p>
                                                        <p><strong>Data Fine:</strong> {formatDate(selectedEvent.end)}</p>
                                                        <p><strong>Ore
                                                            Lavorate:</strong> {selectedEvent.extendedProps.oreLavorate}</p>
                                                        <p><strong>Prezzo
                                                            orario:</strong> € {selectedEvent.extendedProps.prezzoOrario}
                                                        </p>
                                                        <p>
                                                            <strong>Stato:</strong> {capitalizeFirstLetter(selectedEvent.extendedProps.stato)}
                                                        </p>
                                                        <p>
                                                            <strong>Fatturato:</strong> {selectedEvent.extendedProps.fatturato}
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="secondary" onClick={toggleModal}>Chiudi</Button>
                                        </ModalFooter>
                                    </Modal>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

Calender.propTypes = {
    events: PropTypes.array,
    categories: PropTypes.array,
    className: PropTypes.string,
    onGetEvents: PropTypes.func,
    onAddNewEvent: PropTypes.func,
    onUpdateEvent: PropTypes.func,
    onDeleteEvent: PropTypes.func,
    onGetCategories: PropTypes.func,
};

export default Calender;

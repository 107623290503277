import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, Card, CardBody, CardTitle, Col, Row} from "reactstrap";


import axios from "axios";
import {Bar, Pie} from "react-chartjs-2";
import {Link} from "react-router-dom";


const UserPanel = () => {

    const apiUrl = process.env.REACT_APP_API_URL_MONGO;
    const token = process.env.REACT_APP_TOKEN_MONGO;

    const [interventiUltimoMese, setInterventiUltimoMese] = useState('');
    const [nuoviClientiUltimoMese, setNuoviClientiUltimoMese] = useState('');
    const [chartData, setChartData] = useState('');
    const [chartDataPie, setChartDataPie] = useState('');

    useEffect(() => {

        axios.get(apiUrl + 'api/dashboard-data', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {

            const dati = response.data;

            setInterventiUltimoMese(dati.interventiUltimoMese.count);
            setNuoviClientiUltimoMese(dati.nuoviClientiUltimoMese.count);

            // Popola il Grafico Bar
            const dataBar = {
                labels: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
                datasets: [
                    {
                        label: "Numero di interventi",
                        data: dati.interventiMensiliBar.interventiMensili.dataset,
                        backgroundColor: "#3d8ef8",
                        barThickness: 30,

                    },
                ],
            };

            setChartData(dataBar);

            // Popola il Grafico Pie
            const dataPie = {
                labels: dati.clientiPie.labels
                ,
                datasets: [
                    {
                        data: dati.clientiPie.data,
                        backgroundColor: [
                            '#0db4d6',
                            '#f1b44c',
                            '#fb4d53',
                            '#343a40'
                        ],
                        borderColor: [
                            '#0db4d6',
                            '#f1b44c',
                            '#fb4d53',
                            '#343a40'
                        ],
                        hoverBackgroundColor: ["#34c38f", "#ff3d60","#4aa3ff","#212529"],
                        hoverBorderColor: "#fff",
                    },
                ],
            }
            setChartDataPie(dataPie);

        });

    }, [apiUrl, token]);

    const option = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            dataset: [
                {
                    display: false,
                    gridLines: {
                        drawBorder: false,
                        offset: true,
                    },
                    ticks: {
                        fontColor: "#2150ae",
                    },
                },
            ],
            dataset1: [
                {
                    barPercentage: 0.7,
                    categoryPercentage: 0.5,

                    ticks: {
                        fontColor: "#7b919e",
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false,
                    },
                    elements: {
                        point: {
                            radius: 0
                        }
                    }
                },
            ],
        },
    };

    return (
        <React.Fragment>
        <Row>
            <Col xl={6} sm={6}>
                <Card>
                    <CardBody>
                        <CardTitle>Accesso Rapido</CardTitle>
                        <ButtonGroup>
                            <Link to="/clienti-inserisci">
                                <Button color="primary" className="me-4">Aggiungi Nuovo Cliente</Button>
                            </Link>
                            <Link to="/interventi-crea">
                                <Button color="success" className="me-4">Programma Intervento</Button>
                            </Link>
                            <Link to="/calendar">
                                <Button color="info" className="me-4">Visualizza Calendario</Button>
                            </Link>
                        </ButtonGroup>

                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardTitle>Numero di interventi</CardTitle>
                        <p className="card-title-desc">Numero di interventi effettuati ogni mese nell'ultimo
                            anno
                        </p>
                        <Bar width={537} height={268} data={chartData} options={option}/>
                    </CardBody>
                </Card>
            </Col>
            <Col xl={6} sm={6}>
                <Row>
                    <Col xl={6} sm={6}>
                        <Card>
                            <CardBody>
                                <div className="d-flex text-muted">
                                    <div className="flex-shrink-0 me-3 align-self-center">
                                        <div className="avatar-sm">
                                            <div
                                                className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                <i className="mdi mdi-file-cog"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1 overflow-hidden">
                                        <p className="mb-1">Interventi nell'ultimo mese</p>
                                        <h5 className="mb-3">{interventiUltimoMese}</h5>
                                        <p className="text-truncate mb-0">
                                            <span className="text-success me-2">
                                                {" "}
                                                0.01%{" "}
                                                <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                                            </span>
                                            {" "}
                                            From previous
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={6} sm={6}>
                        <Card>
                            <CardBody>
                                <div className="d-flex text-muted">
                                    <div className="flex-shrink-0 me-3 align-self-center">
                                        <div className="avatar-sm">
                                            <div
                                                className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                <i className="mdi mdi-account-multiple"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1 overflow-hidden">
                                        <p className="mb-1">Nuovi clienti nell'ultimo mese</p>
                                        <h5 className="mb-3">{nuoviClientiUltimoMese}</h5>
                                        <p className="text-truncate mb-0">
                                            <span className="text-success me-2">
                                                {" "}
                                                0.01%{" "}
                                                <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                                            </span>
                                            {" "}
                                            From previous
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} sm={12}>
                        <Card>
                            <CardBody>
                                <CardTitle>Interventi Clienti </CardTitle>
                                <Pie width={537} height={268} data={chartDataPie} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>

        </Row>
    </React.Fragment>);
};

export default UserPanel;

import React from "react";
import {Container} from "reactstrap";
//Import Breadcrumb

import Breadcrumbs from "../../components/Common/Breadcrumb";
import BillingDataForm from "./Component/BillingDataForm";



const BillingCl = () => {
    document.title = "Dashboard | Billing Clienti";
    return (
        <React.Fragment>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Billing Clienti" breadcrumbItem="Clienti" />
                        <BillingDataForm />
                    </Container>
                </div>
            </React.Fragment>
        </React.Fragment>
    );
};

export default BillingCl;